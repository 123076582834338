import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setOpenSidebar } from "../../store/reducers/globalReducer";
import { ArrowUpIcon, ArrowDownIcon, MenuIcon } from "../../assets/Icons";
import { HeaderButtons } from "./headerBtns";
import CurrencyTicker from "../CurrencyTicker";
import Whitepaper from "../../assets/images/bitcoin.pdf";

const Header = () => {
  const dispatch = useDispatch();
  const [showProductsMenu, setShowProductsMenu] = useState(false);
  const [showAboutMenu, setShowAboutMenu] = useState(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  useEffect(() => {
    document.addEventListener("click", () => {
      document.body.style.overflow = "inherit";
      setShowProductsMenu(false);
      setShowAboutMenu(false);
      setShowMoreMenu(false);
    });
  }, []);

  return (
    <>
      <div className={"currencyTicker"}>
        <CurrencyTicker />
      </div>
      <div className="header-camp flex ">
        <div className="wrap flex items-center">
          <div className="left flex items-center">
            <div className="logo-img flex items-center justify-center">
              <Link to="/">
                <img src="../images/logoW.svg" className="logo" />
              </Link>
            </div>
            <div className="menu-list flex items-center text-white">
              {/* Products */}
              <div
                className="menu-item flex items-center relative"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowProductsMenu(!showProductsMenu);
                  setShowAboutMenu(false);
                  setShowMoreMenu(false);
                }}
                onMouseEnter={() => setShowProductsMenu(true)}
                onMouseLeave={() => setShowProductsMenu(false)}
              >
                <h1 className="lbl">Products</h1>
                <div className="icon flex items-center justify-center ">
                  {showProductsMenu ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </div>
                <div
                  className={`sub-menu-list absolute flex flex-col ${
                    showProductsMenu ? "show" : "hide"
                  }`}
                >
                  <div className="_sub-menus flex flex-col">
                    <Link
                      to="/bitcoinBackedLoans"
                      className="_sub-menu-item flex items-center cursor-pointer"
                    >
                      <h1 className="sub-menu-lbl">
                        Bitcoin
                        <br /> Backed Loans
                      </h1>
                    </Link>
                  </div>
                </div>
              </div>

              {/* About */}
              <div
                className="menu-item flex items-center relative"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAboutMenu(!showAboutMenu);
                  setShowProductsMenu(false);
                  setShowMoreMenu(false);
                }}
                onMouseEnter={() => setShowAboutMenu(true)}
                onMouseLeave={() => setShowAboutMenu(false)}
              >
                <h1 className="lbl">About</h1>
                <div className="icon flex items-center justify-center ">
                  {showAboutMenu ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </div>
                <div
                  className={`sub-menu-list absolute flex flex-col ${
                    showAboutMenu ? "show" : "hide"
                  }`}
                >
                  <div className="_sub-menus flex flex-col">
                    <Link
                      to="/aboutus"
                      className=" _sub-menu-item flex items-center cursor-pointer"
                    >
                      <h1 className="sub-menu-lbl">About Us</h1>
                    </Link>

                    <Link
                      to="/security"
                      className="_sub-menu-item flex items-center cursor-pointer"
                    >
                      <h1 className="sub-menu-lbl">Security</h1>
                    </Link>
                    <Link
                      to="/careers"
                      className="_sub-menu-item flex items-center cursor-pointer"
                    >
                      <h1 className="sub-menu-lbl">Careers</h1>
                    </Link>
                  </div>
                </div>
              </div>

              {/* Learn More */}
              <div
                className="menu-item flex items-center relative"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMoreMenu(!showMoreMenu);
                  setShowProductsMenu(false);
                  setShowAboutMenu(false);
                }}
                onMouseEnter={() => setShowMoreMenu(true)}
                onMouseLeave={() => setShowMoreMenu(false)}
              >
                <h1 className="lbl">Resources</h1>
                <div className="icon flex items-center justify-center ">
                  {showMoreMenu ? <ArrowUpIcon /> : <ArrowDownIcon />}
                </div>
                <div
                  className={`sub-menu-list absolute flex flex-col ${
                    showMoreMenu ? "show" : "hide"
                  }`}
                >
                  <div className="_sub-menus flex flex-col">
                    <Link
                      to="/contactus"
                      className="_sub-menu-item flex items-center cursor-pointer"
                    >
                      <h1 className="sub-menu-lbl">Contact</h1>
                    </Link>
                    <Link
                      to="/blogs"
                      className="_sub-menu-item flex items-center cursor-pointer"
                    >
                      <h1 className="sub-menu-lbl">Blog</h1>
                    </Link>
                    <Link
                      to="/riskManagement"
                      className="_sub-menu-item flex items-center cursor-pointer"
                    >
                      <h1 className="sub-menu-lbl">Risk Management</h1>
                    </Link>
                    {/*<div className="_sub-menu-item flex items-center cursor-pointer">
                      <h1 className="sub-menu-lbl">Blog</h1>
                    </div>*/}
                    <div className="_sub-menu-item flex items-center cursor-pointer">
                      <a href={Whitepaper} target="_blank">
                        <h1 className="sub-menu-lbl">Bitcoin Whitepaper</h1>
                      </a>
                    </div>
                    {/* <div className="__sub-menu-item flex items-center cursor-pointer">
                                            <Link
                                                to="/bitcoinchart"
                                                className="_sub-menu-item flex items-center cursor-pointer"
                                            >
                                                <h1 className="sub-menu-lbl">Bitcoin Charts</h1>
                                            </Link>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right flex items-center justify-end">
            <HeaderButtons />
            <div
              className="Menu-Icon hidden items-center justify-center"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setOpenSidebar(true));
              }}
            >
              <MenuIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
