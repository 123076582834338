import Swal from "sweetalert2";

const customAlert = (data) => {
  Swal.fire({
    title: data.title,
    text: data.text,
  });
};

const RemainBalance = (data) => {
  var Unpaid = 0;
  data.map((item) => {
    if (item?.paid === null) {
      Unpaid += Number(item?.payment_due);
    }
  });
  return Unpaid;
};

const passRegExp = "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).{8,}";

const apiUrl = "https://bit-lock-backend.onrender.com/api/";
//
const imagesUrl = "https://bit-lock-backend.onrender.com/uploads/";
// const apiUrl = "http://localhost:8080/api/"
// const imagesUrl = "http://localhost:8080/uploads/"

function DateTimeFormatter(timestamp) {
  // Convert the timestamp to a Date object
  const date = new Date(timestamp);

  // Format the date and time using toLocaleString
  return date.toLocaleString(undefined, {
    month: "long",
    year: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export {
  customAlert,
  passRegExp,
  apiUrl,
  imagesUrl,
  RemainBalance,
  DateTimeFormatter,
};
