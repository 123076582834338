import React, { useEffect, useState } from "react";
import AdminWrapper from "../../../routes/SuperAdminWrapper";
import { useLocation } from "react-router-dom";
import makeRequest from "../../../helpers/axiosRequest";
import {
  apiUrl,
  customAlert,
  DateTimeFormatter,
} from "../../../helpers/MyHelpers";
import { Toggle } from "../../../components/Toggle/Toggle";

const LoanView = () => {
  const { state } = useLocation();
  const [approved, setApproved] = useState(state.approved);
  const [adminWallet, setAdminWallet] = useState(state?.admin_wallet);
  const [monthlyInstallment, SetMonthlyInstallment] = useState(0);

  useEffect(() => {
    monthlyInstallmentCalculator();
  }, [approved]);

  const articlesOfIncorporation = state.articles_of_incorporation;

  // Check if the string contains "/uploads/"
  const startIndex = articlesOfIncorporation.indexOf("/uploads/");

  const fileName =
    startIndex !== -1
      ? articlesOfIncorporation.slice(startIndex + "/uploads/".length)
      : articlesOfIncorporation; // If "/uploads/" not found, use the whole string

  const IsCanceled = approved?.toLowerCase().includes("canceled");
  // Loan Calculator
  const monthlyInstallmentCalculator = () => {
    let barrow_amount = parseInt(state?.borrow_amount.replace(/,/g, ""));
    let returnAmount = (barrow_amount - barrow_amount * 0.025) * 0.125;
    let monthlyReturn = returnAmount / parseInt(state?.term_length);
    monthlyReturn = +(Math.round(monthlyReturn + "e+2") + "e-2");
    SetMonthlyInstallment(monthlyReturn);
  };
  const saveNotes = async (value, index) => {
    try {
      const formData = new FormData();
      formData.append("notes", value);
      const response = await makeRequest(
        "post",
        `${apiUrl}admin/save_payment_notes/${state?.payments[index]?._id}`,
        formData
      );
    } catch (err) {
      customAlert({
        icon: "error",
        title: "Unexpected Error",
        text: "Try again later",
      });
    }
  };
  const handleCheck = async (value, index) => {
    try {
      const formData = new FormData();
      formData.append("check", value);
      const response = await makeRequest(
        "post",
        `${apiUrl}admin/save_payment_check/${state?.payments[index]?._id}`,
        formData
      );
    } catch (err) {
      customAlert({
        icon: "error",
        title: "Unexpected Error",
        text: "Try again later",
      });
    }
  };

  let generatePayments = () => {
    if (approved === "Rejected" || approved === "") {
      return;
    }
    let data = [];
    if (approved === "Approved" || approved === "Canceled") {
      if (state?.payments?.length > 0) {
        data.push(
          <tr>
            <th className="border font-normal px-6 py-3">Billing Period</th>
            <th className="border font-normal px-6 py-3">Payment Due</th>
            <th className="border font-normal px-6 py-3">Date</th>
            <th className="border font-normal px-6 py-3">Remaining Interest</th>
            <th className="border font-normal px-6 py-3">Paid By</th>
            <th className="border font-normal px-6 py-3">Check</th>
            <th className="border font-normal px-6 py-3" width={400}>
              Notes
            </th>
          </tr>
        );
        for (let i = 0; i < state?.payments?.length; i++) {
          data.push(
            <tr key={i}>
              <th className="border huhu font-normal px-6 py-3">
                <input
                  type="text"
                  readOnly={true}
                  name="billing_period[]"
                  value={state.payments[i].billing_period}
                />
              </th>
              <th className="border font-normal px-6 py-3">
                <input
                  type="number"
                  readOnly={true}
                  name="payment_due[]"
                  value={state.payments[i].payment_due}
                />
              </th>
              <th className="border font-normal px-6 py-3">
                <input
                  type="date"
                  readOnly={true}
                  name="date[]"
                  value={state.payments[i].date.toString().split("T")[0]}
                />
              </th>
              <th className="border font-normal px-6 py-3">
                <input
                  readOnly={true}
                  name="remaining_principle[]"
                  type="number"
                  value={state.payments[i].remaining_principle}
                />
              </th>
              <th className="border font-normal px-6 py-3">
                {state.payments[i].paid}
              </th>
              <th className="border font-normal px-6 py-3">
                <Toggle
                  value={state.payments[i]?.check === true}
                  index={i}
                  handleChange={handleCheck}
                />
              </th>
              <th className="border font-normal px-6 py-3">
                <textarea
                  className="w-full"
                  rows="2"
                  onChange={(e) => {
                    saveNotes(e.target.value, i);
                  }}
                >
                  {state.payments[i]?.notes}
                </textarea>
              </th>
            </tr>
          );
        }
      } else {
        let principle = monthlyInstallment * state.term_length;
        let monthly = monthlyInstallment;
        let OldDate = new Date();
        let date = new Date(OldDate.setMonth(OldDate.getMonth()));
        data.push(
          <tr>
            <th className="border font-normal px-6 py-3">Billing Period</th>
            <th className="border font-normal px-6 py-3">Payment Due</th>
            <th colSpan={2} className="border font-normal px-6 py-3">
              Date
            </th>
            <th colSpan={2} className="border font-normal px-6 py-3">
              Remaining Interest
            </th>
          </tr>
        );
        for (let i = 1; i <= state.term_length; i++) {
          if (i == state.term_length) {
            principle = +(
              Math.round(principle - monthlyInstallment + "e+2") + "e-2"
            );
            monthly += parseInt(state?.borrow_amount.replace(/,/g, ""));
          } else {
            principle = +(
              Math.round(principle - monthlyInstallment + "e+2") + "e-2"
            );
          }
          const newDate = new Date(date);
          newDate.setFullYear(
            date.getFullYear(),
            date.getMonth() + i,
            date.getDate()
          );
          if (newDate.getDate() !== date.getDate()) {
            newDate.setDate(0);
          }
          let curr = newDate.toISOString().split("T")[0];
          data.push(
            <tr key={i}>
              <th className="border font-normal px-6 py-3">
                <input
                  type="text"
                  readOnly={true}
                  name="billing_period[]"
                  value={i}
                />
              </th>
              <th className="border font-normal px-6 py-3">
                <input
                  type="number"
                  defaultValue={monthly}
                  value={monthly}
                  name="payment_due[]"
                />
              </th>
              <th colSpan={2} className="border font-normal px-6 py-3">
                <input type="date" defaultValue={curr} name="date[]" />
              </th>
              <th colSpan={2} className="border font-normal px-6 py-3">
                <input
                  name="remaining_principle[]"
                  type="number"
                  value={principle}
                />
              </th>
            </tr>
          );
        }
      }
    }
    return data;
  };
  const handleLoan = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      const response = await makeRequest(
        "post",
        `${apiUrl}admin/save_loan_payments/${state?._id}`,
        formData
      ).then((response) => {
        if (response?.data?.status === "Success") {
          customAlert({
            icon: "success",
            title: "Success",
            text: "Loan Payments Saved",
          });
        } else if (response?.error) {
          customAlert({
            icon: "error",
            title: "Error",
            text: response?.error?.data,
          });
        } else {
          customAlert({
            icon: "error",
            title: "Unexpected Error",
            text: "Try again later",
          });
        }
      });
    } catch (err) {
      customAlert({
        icon: "error",
        title: "Unexpected Error",
        text: "Try again later",
      });
    }
  };
  let updateLoanStatus = async (status) => {
    let approval_status = "";
    if (status) {
      approval_status = status;
    }
    setApproved(approval_status);
    try {
      const formData = new FormData();
      formData.append("approved", approval_status);
      formData.append("email", state?.user?.email);
      formData.append("first_name", state?.user?.first_name);
      formData.append("walletName", state?.wallet_group?.group_name);
      const response = await makeRequest(
        "post",
        `${apiUrl}admin/save_loan_approval/${state?._id}`,
        formData
      ).then((response) => {
        if (response?.data?.status === "Success") {
          customAlert({
            icon: "success",
            text: "approved",
          });
        } else if (response?.error) {
          customAlert({
            icon: "error",
            title: "Error",
            text: response?.error?.data,
          });
        } else {
          customAlert({
            icon: "error",
            title: "Unexpected Error",
            text: "Try again later",
          });
        }
      });
    } catch (err) {
      customAlert({
        icon: "error",
        title: "Unexpected Error",
        text: "Try again later",
      });
    }
  };
  let getApprovalBtns = () => {
    if (
      approved == "Approved" ||
      approved == "Rejected" ||
      IsCanceled === true
    ) {
      return;
    } else {
      return (
        <>
          <button
            style={{ backgroundColor: "red" }}
            className="button btn-rounded float-right"
            type="button"
            onClick={() => {
              updateLoanStatus("Rejected");
            }}
          >
            Reject
          </button>
          <button
            className="button btn-rounded float-right"
            type="button"
            onClick={() => {
              updateLoanStatus("Approved");
            }}
          >
            Approve
          </button>
        </>
      );
    }
  };
  let getAssignWalletForm = () => {
    return (
      <div style={{ width: "400px", float: "left", marginLeft: "20px" }}>
        <input
          onChange={(e) => {
            setAdminWallet(e.target.value);
          }}
          value={adminWallet}
          type="text"
          className="border rounded py-2 w-2/3 px-2 leading-tight float-left"
          placeholder="BitLoc Address"
        />
        <button
          className="button btn-rounded float-left ml-3"
          type="button"
          onClick={assignWallet}
        >
          Save
        </button>
      </div>
    );
  };
  let assignWallet = async () => {
    let data = new FormData();
    data.append("admin_wallet", adminWallet);
    try {
      const response = await makeRequest(
        "post",
        `${apiUrl}admin/assign_wallet/${state?._id}`,
        data
      ).then((response) => {
        if (response?.data?.status === "Success") {
          customAlert({
            icon: "success",
            text: "Wallet Assigned",
          });
        } else if (response?.error) {
          customAlert({
            icon: "error",
            title: "Error",
            text: response?.error?.data,
          });
        } else {
          customAlert({
            icon: "error",
            title: "Unexpected Error",
            text: "Try again later",
          });
        }
      });
    } catch (err) {
      customAlert({
        icon: "error",
        title: "Unexpected Error",
        text: "Try again later",
      });
    }
  };
  return (
    <AdminWrapper>
      <div className="dashboard-page flex bg-white">
        <div className="page-block flex flex-col w-full">
          <div className="page-title text-black">Loan Details</div>
          <div className="rounded shadow-[1px_2px_4px_4px_rgba(0,0,0,0.1)] p-5 overflow-x-auto">
            <form onSubmit={handleLoan}>
              <table className="w-full text-left">
                <tbody>
                  <tr>
                    <th className="border font-normal px-6 py-3">Full Name</th>
                    <td className="border px-6 py-3">
                      {state.user?.first_name + " " + state.user?.first_name}
                    </td>
                    <th className="border font-normal px-6 py-3">Email</th>
                    <td className="border px-6 py-3">{state.user.email}</td>
                    <th className="border font-normal px-6 py-3">
                      Phone Number
                    </th>
                    <td className="border px-6 py-3">
                      +1{state.user.phone_number}
                    </td>
                  </tr>
                  <tr>
                    <th className="border font-normal px-6 py-3">
                      Borrow Amount
                    </th>
                    <td className="border px-6 py-3">
                      ${Number(state.borrow_amount).toLocaleString()}
                    </td>
                    <th className="border font-normal px-6 py-3">
                      Term Length
                    </th>
                    <td className="border px-6 py-3">{state.term_length}</td>
                    <th className="border font-normal px-6 py-3">Loan Type</th>
                    <td className="border px-6 py-3">{state.loan_type}</td>
                  </tr>
                  <tr>
                    <th className="border font-normal px-6 py-3">Category</th>
                    <td className="border px-6 py-3">
                      {state.category === "Other"
                        ? state.category + " : " + state.use_of_funds
                        : state.category}
                    </td>
                    <th className="border font-normal px-6 py-3">interest</th>
                    <td className="border px-6 py-3">
                      12.5% + 2.5% origination fee
                    </td>
                    <th className="border font-normal px-6 py-3">Status</th>
                    <td className="border px-6 py-3">
                      {approved
                        ? approved +
                          " On " +
                          DateTimeFormatter(state?.updatedAt)
                        : "Pending Approval"}
                    </td>
                  </tr>
                  <tr>
                    <th className="border font-normal px-6 py-3">
                      Account Name
                    </th>
                    <td className="border px-6 py-3">
                      {state?.bank?.account_name}
                    </td>
                    <th className="border font-normal px-6 py-3">
                      Account Number
                    </th>
                    <td className="border px-6 py-3">
                      {state?.bank?.account_number}
                    </td>
                    <th className="border font-normal px-6 py-3">Currency</th>
                    <td className="border px-6 py-3">{state?.currency_type}</td>
                  </tr>
                  {state?.loan_type === "Business" ? (
                    <>
                      <tr>
                        <th className="border font-normal px-6 py-3">
                          Company Name
                        </th>
                        <td className="border px-6 py-3">
                          {state?.company_name}
                        </td>
                        <th className="border font-normal px-6 py-3">
                          Registered Address
                        </th>
                        <td className="border px-6 py-3">
                          {state?.registered_address}
                        </td>
                        <th className="border font-normal px-6 py-3">
                          Business Registration Number
                        </th>
                        <td className="border px-6 py-3">
                          {state?.business_registration_number}
                        </td>
                      </tr>
                      <tr>
                        <th className="border font-normal px-6 py-3">
                          Directors Officers
                        </th>
                        <td className="border px-6 py-3">
                          {state?.directors_officers}
                        </td>
                        <th className="border font-normal px-6 py-3">
                          Position Role
                        </th>
                        <td className="border px-6 py-3">
                          {state?.position_role}
                        </td>
                        <th className="border font-normal px-6 py-3">
                          Articles of Incorporation
                        </th>
                        <td className="border px-6 py-3">
                          {state.articles_of_incorporation ? (
                            <div className="flex items-center gap-4 truncate">
                              <span className="truncate"> {fileName} </span>
                              <a
                                href={
                                  "https://bit-lock-backend.onrender.com" +
                                  state.articles_of_incorporation
                                }
                                download="Articles_of_Incorporation.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="py-[8px] px-[25px] bg-[#f9c46e] hover:bg-opacity-80 rounded-full"
                              >
                                Download
                              </a>
                            </div>
                          ) : (
                            "Not provided"
                          )}
                        </td>
                      </tr>
                    </>
                  ) : null}
                  <tr>
                    <th className="border font-normal px-6 py-3">
                      BitLoc Address
                    </th>
                    <th colSpan={5} className="border font-normal px-6 py-3">
                      {getAssignWalletForm()}
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={3} className="border font-normal px-6 py-5">
                      <h2 className="font-normal text-xl float-left">
                        Wallet Group Name
                      </h2>
                    </th>
                    <th colSpan={3} className="border font-normal px-6 py-5">
                      <h2 className="font-normal text-xl float-left">
                        {state?.wallet_group?.group_name}
                      </h2>
                    </th>
                  </tr>
                  <tr>
                    <th colSpan={3} className="border font-normal px-6 py-3">
                      Address
                    </th>
                    <th colSpan={3} className="border font-normal px-6 py-3">
                      Percentage
                    </th>
                  </tr>
                  {state?.wallet_group?.wallets.map((item, key) => {
                    return (
                      <tr>
                        <th
                          colSpan={3}
                          className="border font-normal px-6 py-3"
                        >
                          {item?.address}
                        </th>
                        <th
                          colSpan={3}
                          className="border font-normal px-6 py-3"
                        >
                          {item?.percentage}
                        </th>
                      </tr>
                    );
                  })}

                  <tr>
                    <th colSpan={6} className="border font-normal px-6 py-5">
                      <h2 className="font-normal text-xl float-left">
                        Payments Information
                      </h2>
                      {getApprovalBtns()}
                    </th>
                  </tr>
                </tbody>
              </table>
              <table className="w-full text-left">
                {state?.payments.length > 0 ? (
                  <div className={"tBody"}>
                    <tbody>{generatePayments()}</tbody>
                  </div>
                ) : (
                  <tbody>{generatePayments()}</tbody>
                )}
              </table>
              <div className="flex items-center justify-between p-3">
                {approved === "Approved" && state?.payments.length < 1 ? (
                  <button className="button btn-rounded" type="submit">
                    Submit Loan Details
                  </button>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </AdminWrapper>
  );
};

export default LoanView;
